import React from "react"
import { Link } from "gatsby"
import sanitize from "sanitize-html"

import Layout from "../components/layout"
import SEO from "../components/seo"
import mapboxgl, { LngLatBounds } from "mapbox-gl"
import getArea from "@mapbox/geojson-area"
import classNames from "./index"
import Panel from "../components/panel"

import boat from "../images/boat.png"
import flag from "../images/flag.png"
import person from "../images/person.png"
import point from "../images/point.png"

import "./index.css"

mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9obmh1MjBjYSIsImEiOiJjanZ0dXpwcXoza3lrNDhsNmR5N2NhcXdtIn0.s8J0EFdyckTW8uwQouFJuA"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Application />
  </Layout>
)

class Application extends React.Component<
  {},
  { lng: number; lat: number; zoom: number; data: null | any; hover: boolean }
> {
  map: mapboxgl.Map | null = null
  mapContainer: HTMLDivElement | null = null

  constructor(props: {}) {
    super(props)
    this.state = {
      lng: -122.29,
      lat: 37.425,
      zoom: 12.5,
      data: null,
      hover: false,
    }
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state
    if (!this.mapContainer) {
      throw new Error("oops")
    }

    const map = (this.map = new mapboxgl.Map({
      hash: true,
      container: this.mapContainer,
      style: "mapbox://styles/johnhu20ca/cjvtv1m1r2dzf1cmsvzeaattx/draft",
      center: [lng, lat],
      zoom,
    }))

    map.on("move", () => {
      const { lng, lat } = map.getCenter()

      this.setState({
        lng: lng,
        lat: lat,
        zoom: map.getZoom(),
      })
    })

    map.on("click", e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["hitbox"],
      })

      // Sort by area (smallest first)
      features.sort((a, b) => {
        const aArea = getArea.geometry(a.geometry)
        const bArea = getArea.geometry(b.geometry)
        if (aArea === bArea) return 0
        return aArea > bArea ? 1 : -1
      })

      const feature = features[0]
      if (feature?.properties?.name) {
        this.setState({ data: feature })
      } else {
        this.setState({ data: null })
      }
    })

    map.on("mousemove", e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["hitbox"],
      })

      // Sort by area (smallest first)
      features.sort((a, b) => {
        const aArea = getArea.geometry(a.geometry)
        const bArea = getArea.geometry(b.geometry)
        if (aArea === bArea) return 0
        return aArea > bArea ? 1 : -1
      })

      const feature = features[0]
      if (feature?.properties?.name) {
        this.setState({ hover: true })
      } else {
        this.setState({ hover: false })
      }
    })
  }

  render() {
    const { lng, lat, zoom } = this.state

    return (
      <div
        style={{
          flex: 1,
          position: "relative",
        }}
      >
        <div
          ref={el => (this.mapContainer = el)}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: this.state.hover ? "pointer" : "default",
          }}
        />

        <Panel
          data={this.state.data}
          onSetData={this.onSetData}
          onClose={this.onClose}
        />

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button style={buttonStyle}>
            <img style={imageStyle} src={point} />
          </button>
          <button style={buttonStyle}>
            <img style={imageStyle} src={person} />
          </button>
          <button style={buttonStyle}>
            <img style={imageStyle} src={boat} />
          </button>
          <button style={buttonStyle}>
            <img style={imageStyle} src={flag} />
          </button>
        </div>
      </div>
    )
  }

  onClose = () => {
    this.setState({ data: null })
  }

  onSetData = (data: any) => {
    this.setState({ data })

    const map = this.map
    if (!map) return

    // Geographic coordinates of the LineString
    const coordinates = data.geometry.coordinates
    // const first = coordinates[0]

    // let n = first[0][1]
    // let s = first[0][1]
    // let e = first[0][0]
    // let w = first[0][0]

    // coordinates.forEach(([lng, lat]: [number, number]) => {
    //   if (lng < w) w = lng
    //   if (lng > e) e = lng
    //   if (lat < n) n = lat
    //   if (lat > n) n = lat
    // })

    // const bounds = [
    //   { lng: w, lat: n },
    //   { lng: e, lat: s },
    // ]

    const bounds: LngLatBounds = coordinates.reduce(function(bounds, coord) {
      return bounds.extend(coord)
    }, new LngLatBounds(coordinates[0][0], coordinates[0][0]))

    // map.fitBounds(bounds, {
    //   padding: 200,
    // })

    map.flyTo({ center: bounds.getCenter(), zoom: 20 })
  }
}

const buttonStyle = {
  padding: "2px",
  borderRadius: "20px",
  overflow: "hidden",
  boxSizing: "border-box",
  width: "40px",
  margin: "3px",
  border: "2px solid #606060",
  boxShadow: "0 2px 5px rgba(0,0,0,0.5)",
  cursor: "pointer",
}

const imageStyle = {
  width: "100%",
}

export default IndexPage
