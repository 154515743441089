import React, { useState, useEffect } from "react"
import sanitize from "sanitize-html"

const Panel = ({
  data,
  onSetData,
  onClose,
}: {
  data: null | {
    properties?: {
      name?: string
      summary?: string
      history?: string
      citations?: string
    }
  }
  onSetData: (data: any) => void
  onClose: () => void
}) => {
  const [showMore, setShowMore] = useState(false)
  const [showCitations, setShowCitations] = useState(false)

  const [features, setFeatures] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      try {
        setFeatures(
          (
            await (
              await fetch(
                "https://api.mapbox.com/datasets/v1/johnhu20ca/cjyf2f66k00oi2tmlvckq1shd/features?access_token=pk.eyJ1Ijoiam9obmh1MjBjYSIsImEiOiJjazY0b3U2dGowZGg4M2xvZHQzczRxZzJ6In0.tBQP5HMYziz6jP5t5H2SYQ&limit=100",
                {
                  method: "GET",
                  headers: {},
                }
              )
            ).json()
          ).features
        )
      } catch (error) {
        alert("There was a problem fetching the data. Please refresh.")
      }
    })()
  }, [])

  const [search, setSearch] = useState("")
  const searchTerms = search
    .toLowerCase()
    .split(/\s/)
    .filter(t => t)

  const searchResults =
    features &&
    features.filter(f => {
      const name = f.properties?.name?.toLowerCase()
      if (!name || typeof name !== "string") return false
      return searchTerms.some(t => name.includes(t))
    })

  return (
    <div
      style={{
        background: "white",
        position: "absolute",
        top: "20px",
        left: "20px",
        // bottom: data ? "20px" : undefined,
        maxHeight: "calc(100% - 40px)",
        width: "400px",
        borderRadius: "10px",
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.5)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <div style={{ position: "relative" }}>
        <input
          type="search"
          style={{
            width: "calc(100% - 40px)",
            margin: "20px",
            fontSize: "1.4rem",
            WebkitAppearance: "none",
            border: "2px solid #606060",
            borderRadius: "7px",
            padding: "4px 8px",
          }}
          value={search}
          onChange={e => {
            setSearch(e.currentTarget.value)
          }}
        />
        <span
          style={{
            position: "absolute",
            right: "30px",
            top: "24px",
            fontSize: "1.5rem",
          }}
        >
          &#128269;
        </span>
      </div>
      {search && !data && searchResults && searchResults.length ? (
        <div>
          {searchResults.map(f => {
            return (
              <div
                key={f.id}
                onClick={() => onSetData(f)}
                style={{
                  padding: "10px",
                  borderTop: "1px solid #606060",
                  cursor: "pointer",
                }}
              >
                {f.properties.name}
              </div>
            )
          })}
        </div>
      ) : null}
      {data ? (
        <div style={{ flex: 1, overflow: "auto", padding: "0 10px" }}>
          <h1>
            <button
              type="button"
              onClick={onClose}
              style={{
                fontSize: "1em",
                fontWeight: 800,
                padding: "0px 7px 0px 5px",
                marginRight: "10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              ⟨
            </button>
            {data?.properties?.name}
          </h1>

          {data?.properties?.summary ? (
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: sanitize(data?.properties?.summary),
              }}
            />
          ) : null}

          {data?.properties?.history ? (
            <button
              type="button"
              onClick={() => setShowMore(!showMore)}
              style={{ cursor: "pointer", marginBottom: "10px" }}
            >
              Show {showMore ? "Less" : "More"}
            </button>
          ) : null}

          {data?.properties?.history && showMore ? (
            <>
              <div
                className="body"
                dangerouslySetInnerHTML={{
                  __html: sanitize(data?.properties?.history),
                }}
              />

              {data?.properties?.citations ? (
                <button
                  type="button"
                  onClick={() => setShowCitations(!showCitations)}
                  style={{ cursor: "pointer", marginBottom: "10px" }}
                >
                  {showCitations ? "Hide" : "Show"} Citations
                </button>
              ) : null}

              {data?.properties?.citations && showCitations ? (
                <div
                  className="body"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(data?.properties?.citations),
                  }}
                />
              ) : null}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default Panel
